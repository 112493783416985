$color-primary: #575f75;
$color-secondary: #98a0b3;
$color-secondary_30: #f0f1f4;
$color-ternary: #15181f;

$color-exclamation: #cfb122;
$color-warning: #d89a19;
$color-danger: #c71818;
$color-white: #FFFFFF;

$fontdir: '../fonts/';
//sizes
// ui elements spaces
//$ui-spaces: ( xs: 1.77rem, sm: 1.77rem, md: 2.22rem, lg: 3.55rem, xl: 3.55rem ) !default;
//fonts variables
$ff-base-name:'PTSans-Regular';
$ff-base-bold-name:'PTSans-Bold';


$ff-base: $ff-base-name, sans-serif;
$ff-base-bold: $ff-base-bold-name, sans-serif;

$font-family-main: $ff-base-name, "Helvetica Neue", Arial, sans-serif;
$font-family-main-bold: $ff-base-bold-name, "Helvetica Neue", Arial, sans-serif;
$font-weight-main: 400;
$font-weight-main-bold: 600;
$font-style-main: normal;
$font-size-main: 18px;
$font-size-main-rem: 1.125rem;

$fonturl: $fontdir + $ff-base-name;
$fonturlbold: $fontdir + $ff-base-bold-name;

$font-size-h4: 1.5rem;


@font-face {
    font-family: $ff-base-name;
    src: url(#{$fonturl}.eot), url(#{$fonturl}.otf) format('opentype'), url(#{$fonturl}.woff) format('woff');
    font-style: $font-style-main;
    font-weight: $font-weight-main;
}

@font-face {
    font-family: $ff-base-bold-name;
    src: url(#{$fonturlbold}.eot), url(#{$fonturlbold}.otf) format('opentype'), url(#{$fonturlbold}.woff) format('woff');
    font-style: $font-style-main;
    font-weight: $font-weight-main-bold;
}

:export {
    primaryColor1: $color-primary;
    primaryColor2: $color-secondary;
    primaryColor2_30: $color-secondary_30;
    primaryColor3: $color-ternary;
    exclamationColor: $color-exclamation;
    warningColor: $color-warning;
    dangerColor: $color-danger;
    whiteColor: $color-white;
    fontFamilyBase: $font-family-main;
    fontFamilyBold: $font-family-main-bold;
}